<template>
    <admin-table title="临时停手术" @search="handleSearch" :table="table" :loading="loading">
        <el-table-column type="index" width="50" align="center"/>
        <el-table-column align="center"
                         v-for="(v, idx) in table.columns"
                         :prop="v.field"
                         :label="v.title"
                         :width="v.width"
                         :formatter="v.formatter"
                         :key="idx">
        </el-table-column>
        <el-table-column align="center" label="操作" width="180">
            <template slot-scope="scope">
                <el-button size="small" type="warning" icon="el-icon-edit" @click="handleEdit(scope.row)"/>
                <el-popover style="margin-left: 10px" :ref="`popover-${scope.$index}`" placement="top"
                            width="180">
                    <p>确定删除[{{scope.row.doctor}}]的记录吗？</p>
                    <div>
                        <el-button type="danger" size="mini" @click="handleDel(scope.$index, scope.row)">确定
                        </el-button>
                        <el-button size="mini" type="default"
                                   @click="$refs[`popover-${scope.$index}`].doClose()">取消
                        </el-button>
                    </div>
                    <el-button size="small" type="danger" slot="reference" icon="el-icon-delete"></el-button>
                </el-popover>
            </template>
        </el-table-column>
    </admin-table>
</template>

<script>
    export default {
        data() {
            function datesFormatter(t) {
                return t.dates.join(',')
            }

            // function timesFormatter(t) {
            //     return t.times.join(',')
            // }

            return {
                loading: false,
                table: {
                    buttons: [
                        {
                            type: 'primary',
                            label: '新建临时停诊记录',
                            icon: 'el-icon-plus',
                            path: '/main/worksheet/special/form'
                        }
                    ],
                    search: [
                        {type: 'input', key: 'username', placeholder: '请输入关键词'}
                    ],
                    columns: [
                        {title: '医生', field: 'doctor', width: ''},
                        {title: '日期', field: 'dates', width: '', formatter: datesFormatter},
                        // {title: '时间', field: 'times', width: '', formatter: timesFormatter},
                        {title: '备注', field: 'desc', width: ''},
                    ],
                    data: [],
                    total: 0,
                }
            }
        },
        methods: {
            handleEdit(row) {
                let path = `/main/worksheet/special/form?id=${row.id}`
                this.$store.commit('setRouterHistory', {label: `停手术 - ${row.doctor}`, path: path})
                this.$router.push({
                    path: path
                })
            },
            async handleDel(idx, row) {
                this.$refs[`popover-${idx}`].doClose()
                const resp = await this.$http.delete(`/surgery/special/${row.id}`)
                console.log(resp)
                this.$message.success("删除成功")
                this.getData()
            },
            async getData() {
                const resp = await this.$http.get(`/surgery/special/`)
                this.table.data = resp.data.data
            },
            handleSearch(e) {
                this.getData(e)
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>

